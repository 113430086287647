<template>
  <div
    class="treatment"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <Breadcrumb />

    <div class="treatment_header">
      <div
        v-if="score"
        property="aggregateRating"
        itemprop="aggregateRating"
        itemscope
        itemtype="https://schema.org/AggregateRating"
        class="promotion__header__score"
      >
        <span
          class="util-snippet"
          itemprop="ratingValue"
        >{{ score }}</span>
        <span
          class="util-snippet"
          itemprop="bestRating"
        >{{ maxScore }}</span>
        <span
          class="util-snippet"
          itemprop="reviewCount"
        >{{ reviewCount }}</span>
      </div>

      <TreatmentSmallDescription
        :treatment="treatment"
        :location="location"
      >
        <template #title>
          <h1
            itemprop="name"
            class="treatment__title"
          >
            {{ title }}
          </h1>
        </template>
      </TreatmentSmallDescription>
    </div>

    <!-- Service list -->
    <div class="treatment__list-wrapper">
      <!-- filtros -->
      <div>
        <div class="only-desktop-block">
          <Card
            desktop-with-border
            class="treatment__filters"
          >
            <div class="treatment__filters__title">
              <Lang
                by-key="filter-by"
                capitalize-first
              />
            </div>

            <div
              v-if="filterLocationsTree.length"
              class="treatment__filters__expanded-filter"
            >
              <p class="treatment__filters__expanded-filter__header">
                <Lang
                  by-key="region"
                  capitalize-first
                />
              </p>

              <LocationLinkTree
                :countries="countries"
                :link-url-base="locationFilterBaseUrl"
                :general-parent-location="generalParentLocation"
                :locations-tree="filterLocationsTree"
                :current-location="location"
                :link-query="paxLinkQueryParam"
              />
              <hr>
            </div>

            <div
              v-if="parentTreatments.length"
              class="treatment__filters__expanded-filter"
            >
              <p class="treatment__filters__expanded-filter__header">
                <Lang
                  v-if="location"
                  by-key="treatment-in"
                  :params="{location: location.label}"
                  plural
                  capitalize-first
                />
                <Lang
                  v-else
                  by-key="treatment"
                  plural
                  capitalize-first
                />
              </p>

              <div
                v-for="parent in parentTreatments"
                :key="parent.treatment.uuid"
              >
                <SplNuxtLink
                  :to="parent.landing.uri"
                  no-lang
                  is-external
                >
                  <p
                    class="treatment__filters__expanded-filter__link"
                    :class="{'current-treatment': isCurrentTreatmentOrParent(parent.treatment)}"
                  >
                    {{ parent.treatment.title }}
                  </p>
                </SplNuxtLink>

                <div v-if="isCurrentTreatmentOrParent(parent.treatment)">
                  <TruncatedList
                    v-if="childTreatments.length"
                    :split-at="8"
                    :data="childTreatments"
                    expand-button-left
                    no-gap
                  >
                    <template #default="{ element }">
                      <SplNuxtLink
                        :to="element.landing.uri"
                        no-lang
                        is-external
                      >
                        <div
                          class="treatment__filters__expanded-filter__link"
                          :class="{'current-treatment': element.treatment.uuid === treatment.uuid}"
                        >
                          <ArrowIcon
                            direction="right"
                            class="mr-2"
                          />
                          <p>{{ element.treatment.title }}</p>
                        </div>
                      </SplNuxtLink>
                    </template>
                  </TruncatedList>
                </div>
              </div>
              <hr>
            </div>

            <div class="treatment__filters__expanded-filter">
              <p class="treatment__filters__expanded-filter__header">
                <Lang
                  by-key="person"
                  capitalize-first
                  plural
                />
              </p>

              <PaxInlineSelector />
            </div>
          </Card>
        </div>
        <div class="only-mobile">
          <div class="treatment__mobile-filters">
            <StickyHeader>
              <template #only-sticky>
                <div class="treatment__mobile-filters__header">
                  <h1 class="treatment__mobile-filters__header__title">
                    {{ title }}
                  </h1>

                  <div class="promotion__mobile-filters__header__location-selector">
                    <SessionLocationSelector dark />
                  </div>
                </div>
              </template>
              <template #body>
                <div class="treatment__mobile-filters__header">
                  <div class="treatment__mobile-filters__header__controls">
                    <button
                      class="treatment__mobile-filters__button"
                      @click="setIsModalDisplayed(true)"
                    >
                      <HopperIcon
                        class="treatment__mobile-filters__button__icon"
                        :size="18"
                      />
                      <span><Lang
                        by-key="filter"
                        capitalize-first
                      /></span>
                    </button>

                    <SelectBox
                      id="treatment-sorter"
                      variant="minified"
                      :data="sortOptions"
                      display-prop="label"
                      class="treatment__mobile-filters__sort-selector"
                      @input="pushSortOrder"
                    >
                      <template #label>
                        <Lang
                          by-key="sort-by"
                          capitalize-first
                        />: <span class="treatment__mobile-filters__sort-selector__label">{{
                          selectedSortOptionLabel }}</span>
                      </template>
                    </SelectBox>
                  </div>
                </div>
              </template>
            </StickyHeader>

            <ConfigModalMobile
              v-model="isModalDisplayed"
              is-apply-button-displayed
            >
              <template #header>
                <div class="treatment__mobile-filters__header">
                  <Lang
                    by-key="filter-by"
                    capitalize-first
                  />
                </div>
              </template>

              <div
                class="treatment__mobile-filters__expanded-filter"
              >
                <p class="treatment__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="region"
                    capitalize-first
                  />
                </p>

                <LocationLinkTree
                  :countries="countries"
                  :link-url-base="locationFilterBaseUrl"
                  :general-parent-location="generalParentLocation"
                  :locations-tree="filterLocationsTree"
                  :current-location="location"
                  :link-query="paxLinkQueryParam"
                />
              </div>
              <hr>

              <div
                v-if="parentTreatments.length"
                class="treatment__filters__expanded-filter"
              >
                <p class="treatment__filters__expanded-filter__header">
                  <Lang
                    v-if="location"
                    by-key="treatment-in"
                    :params="{location: location.label}"
                    plural
                    capitalize-first
                  />
                  <Lang
                    v-else
                    by-key="treatment"
                    plural
                    capitalize-first
                  />
                </p>
                <div
                  v-for="parent in parentTreatments"
                  :key="parent.treatment.uuid"
                >
                  <SplNuxtLink
                    :to="parent.landing.uri"
                    no-lang
                    is-external
                  >
                    <p
                      class="treatment__mobile-filters__expanded-filter__link"
                      :class="{'current-treatment': isCurrentTreatmentOrParent(parent.treatment)}"
                    >
                      {{ parent.treatment.title }}
                    </p>
                  </SplNuxtLink>

                  <div v-if="isCurrentTreatmentOrParent(parent.treatment)">
                    <TruncatedList
                      v-if="childTreatments.length"
                      :split-at="8"
                      :data="childTreatments"
                      expand-button-left
                      no-gap
                    >
                      <template #default="{ element }">
                        <SplNuxtLink
                          :to="element.treatment.uri"
                          is-external
                        >
                          <div
                            class="treatment__mobile-filters__expanded-filter__link"
                            :class="{'current-treatment': element.treatment.uuid === treatment.uuid}"
                          >
                            <ArrowIcon
                              direction="right"
                              class="mr-2"
                            />
                            <p>{{ element.treatment.title }}</p>
                          </div>
                        </SplNuxtLink>
                      </template>
                    </TruncatedList>
                  </div>
                </div>
              </div>
              <hr>

              <div class="treatment__mobile-filters__expanded-filter">
                <p class="treatment__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="person"
                    capitalize-first
                    plural
                  />
                </p>

                <PaxInlineSelector />
              </div>
            </ConfigModalMobile>
          </div>
        </div>
      </div>

      <div class="treatment__service-list">
        <div class="treatment__service-list__header">
          <div>
            <h2 class="treatment__service-list__header__subtitle">
              {{ subtitle }}
            </h2>
          </div>

          <div>
            <SelectBox
              id="treatment-sorter"
              variant="minified"
              :data="sortOptions"
              display-prop="label"
              class="treatment__service-list__header__sort-selector"
              @input="pushSortOrder"
            >
              <template #label>
                <Lang
                  by-key="sort-by"
                  capitalize-first
                />: <span class="treatment__service-list__header__sort-selector__label">{{
                  selectedSortOptionLabel }}</span>
              </template>
            </SelectBox>
          </div>
        </div>

        <TruncatedList
          v-if="servicesView.length"
          :split-at="24"
          :data="servicesView"
          desktop-table-col-2
        >
          <template #default="{ element }">
            <ServiceListItem
              :service="element"
              :spa="getSpaByUuid(element.spaUUID)"
              show-spa-details
              :selected-pax="selectedPax"
              :has-selected-pax="hasSelectedPax"
            />
          </template>
        </TruncatedList>

        <div
          v-else
          class="treatment__service-list__no-treatment"
        >
          <p class="treatment__service-list__no-treatment__header">
            <Lang
              by-key="no-available-treatments"
              capitalize-first
            />
          </p>
          <p>
            <Lang
              by-key="no-available-treatments-secondary"
              capitalize-first
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'
import TreatmentSmallDescription from '~/core/components/treatment/TreatmentSmallDescription.vue'
import TruncatedList from '~/core/components/shared/TruncatedList.vue'
import LocationLinkTree from '~/core/components/location/LocationLinkTree.vue'
import ServiceListItem from '~/core/components/spa-service/atom/ServiceListItem.vue'
import StickyHeader from '~/core/components/shared/StickyHeader.vue'
import ConfigModalMobile from '~/core/components/shared/form/ConfigModalMobile.vue'
import Card from '~/core/components/shared/Card.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import PaxInlineSelector from '~/core/components/shared/form/PaxInlineSelector.vue'
import SessionLocationSelector from '~/core/components/menu/session-location/SessionLocationSelector.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import HopperIcon from '~/core/components/shared/icons/Hopper.icon.vue'
import ArrowIcon from '~/core/components/shared/icons/Arrow.icon.vue'

import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { usePaxSelector } from '~/core/composable/spa-service/usePaxSelector'
import { useSorter } from '~/core/composable/spa/useSorter'
import { capitalizeAllWords, capitalizeFirstWord, joinPaths } from '~/core/ts/util/string'
import { getTitleAndDescription, getTreatmentMetadata } from '~/core/composable/treatment/useTreatmentPageHeader'

import { ServiceCollection, type Service } from '~/core/ts/entity/Service'
import type { Spa } from '~/core/ts/entity/Spa'
import type { Treatment, TreatmentAndLanding, TreatmentChain } from '~/core/ts/entity/Treatment'
import type { TreatmentLanding } from '~/core/ts/entity/TreatmentLanding'
import type { Location, LocationTree } from '~/core/ts/entity/Location'
import { translateByKey } from '~/core/ts/util/translate'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'

export default defineNuxtComponent({
  components: {
    Breadcrumb,
    TreatmentSmallDescription,
    TruncatedList,
    LocationLinkTree,
    ServiceListItem,
    StickyHeader,
    ConfigModalMobile,
    Card,
    SelectBox,
    PaxInlineSelector,
    SessionLocationSelector,
    SplNuxtLink,
    HopperIcon,
    ArrowIcon,
  },
  async setup() {
    const route = useRoute()
    const runtimeConfig = useRuntimeConfig()

    const sessionLocation = useSessionLocation()

    const {
      selectedPax,
      hasSelectedPax,
      selectedPaxValue,
      addOneToPax,
      subOneToPax,
    } = usePaxSelector(ref([]), '0')

    const paxLinkQueryParam = computed(() => {
      if (!hasSelectedPax.value) {
        return {}
      }

      return { pax: selectedPax.value.value }
    })

    const {
      currentLang,
    } = useLangSelector()

    const recommendedText = capitalizeAllWords(translateByKey('recommended', true))
    const cheapestServiceText = capitalizeAllWords(translateByKey('cheapest-service'))
    const mostExpensiveServiceText = capitalizeAllWords(translateByKey('most-expensive-service'))

    const {
      sortOrder,
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel
    } = useSorter([
      { label: cheapestServiceText, value: 'cheapest' },
      { label: mostExpensiveServiceText, value: 'more_expensive' },
      { label: recommendedText, value: 'recommended' },
    ], 'cheapest')

    // --- Obtener datos
    const countries = await useFetchProxy<Array<Location>>(
      '/api/locations/locations-by-type',
      { method: 'post', body: { type: 'country' } },
    )

    const uri = route.path

    const {
      treatmentParent,
      treatment,
    } = await (async () => {
      const chain = await useFetchProxy<TreatmentChain>(
        '/api/treatments/treatment-chain-by-uri', { method: 'post', body: { uri } })

      const treatmentParent: Treatment | null = chain.parent
      const treatment: Treatment = chain.treatment
      return {
        treatmentParent,
        treatment,
      }
    })()

    const treatmentLanding = await useFetchProxy<TreatmentLanding>(
      '/api/treatments/treatment-landing-by-uri', { method: 'post', body: { uri } })

    // --- Localización
    let location: Location | null = null
    let generalParentLocation: Location | null = null
    const filterLocationsTree: LocationTree[] = []

    if (treatmentLanding.locationUuid) {
      location = await useFetchProxy<Location>(
        '/api/locations/location-by-uuid', {
          method: 'post',
          body: { uuid: treatmentLanding.locationUuid },
          key: treatmentLanding.locationUuid
        })

      if (location && location.parentUUID) {
        const trees = await useFetchProxy<LocationTree[]>(
          '/api/treatments/location-tree-siblings-by-parent-uuid',
          {
            method: 'post',
            body: {
              uuid: location.parentUUID,
              tagUuid: treatment.uuid
            },
          })
        trees.forEach((tree) => filterLocationsTree.push(tree))

        const locationParentResponse = await useFetch<Location>(
          '/api/locations/location-by-uuid', {
            method: 'post',
            body: {
              uuid: location.parentUUID
            },
            key: location.parentUUID
          })
        generalParentLocation = locationParentResponse.data.value as Location
      }
    }

    if (!filterLocationsTree.length) {
      const trees = await useFetchProxy<LocationTree[]>(
        '/api/treatments/location-tree-with-no-parent',
        {
          method: 'post',
          body: {
            tagUuid: treatment.uuid,
            lang: runtimeConfig.public.language,
          },
          key: treatment.uuid
        }
      )
      trees.forEach((tree) => filterLocationsTree.push(tree))
    }

    // --- Fin Localización

    // --- Tratamiento, tratamiento padre y sub-tratamientos
    const uuids = treatmentParent ? [treatmentParent.uuid] : [treatment.uuid]
    const childTreatments = await useFetchProxy<Array<TreatmentAndLanding>>(
      '/api/treatments/treatment-list-by-parent-uuid-list', {
        method: 'post',
        body: {
          uuids: uuids,
          langcode: currentLang,
          location,
          treatmentUUID: treatment.uuid,
        }
      }
    )

    const parentTreatments = await useFetchProxy<Array<TreatmentAndLanding>>(
      '/api/treatments/treatment-list-with-no-parent', {
        method: 'post', body: {
          location,
          langcode: currentLang,
          treatmentParentUUID: treatmentParent ? treatmentParent.uuid : null,
          treatmentUUID: treatment.uuid,
        }
      }
    )

    // --- Servicios
    const services = await useFetchProxy<Service[]>(
      '/api/services/services-by-uuid-list', { method: 'post', body: { uuids: treatmentLanding.payload } })

    // --- Spas
    const spaUUIDsDict: Record<string, string> = {}
    services.forEach((service) => spaUUIDsDict[service.spaUUID] = service.spaUUID)
    const spaUUIDs = Object.keys(spaUUIDsDict)

    const spas = await useFetchProxy<Spa[]>(
      '/api/spas/spa-list-by-uuid-list-no-services', { method: 'post', body: { uuids: spaUUIDs } })

    const spasByUuid: Record<string, Spa> = {}
    spas.forEach((spa) => spasByUuid[spa.uuid] = spa)

    // --- Fin. Obtener datos

    function getSpaByUuid(uuid: string): Spa {
      return spasByUuid[uuid]
    }

    const servicesView = computed(() => {
      const collection = new ServiceCollection(services.slice())

      const selectedCountry = sessionLocation.storedLocationCountry.value
      if (!location && selectedCountry) {
        collection.filterByCountry(selectedCountry)
      }

      // Filtro por pareja/no pareja
      if (selectedPax.value.value !== '0') {
        const pax = Number(selectedPax.value.value)
        collection.filterByPaxValue(pax)
      }

      switch (sortOrder.value.value) {
        case 'cheapest':
          collection.sortByCheaper(new Date())
          break
        case 'more_expensive':
          collection.sortByMostExpensive(new Date())
          break
        case 'recommended':
          collection.sortByRecommended()
          break
        default:
        collection.sortByStatus()
      }

      return collection.services
    })

    const firstServiceImage = (() => {
      if (services.length === 0) {
        return ''
      }

      if (services[0].images.length === 0 ) {
        return ''
      }

      return services[0].images[0].url
    })()
    useHead(getTreatmentMetadata(uri, treatment, location, firstServiceImage))

    const foundTexts = capitalizeFirstWord(translateByKey('found', false))
    const foundTextp = capitalizeFirstWord(translateByKey('found', true))
    const { title } = getTitleAndDescription(treatment, location)
    const subtitle = computed(() => {
      if (servicesView.value.length === 1) {
        return `${foundTexts} 1 Servicio`
      }

      return `${foundTextp} ${servicesView.value.length} Servicios`
    })

    const isModalDisplayed = ref(false)
    function setIsModalDisplayed(value: boolean) {
      isModalDisplayed.value = value
    }

    const locationFilterBaseUrl = (() => {
      let url = '/'
      if (treatmentParent) {
        url = joinPaths(url, treatmentParent.slug)
      }

      if (treatment) {
        url = joinPaths(url, treatment.slug)
      }

      return url
    })()

    function isCurrentTreatmentOrParent(treat: Treatment) {
      if (treat.uuid === treatment.uuid) {
        return true
      }

      if ((treatmentParent && treat.uuid === treatmentParent.uuid)) {
        return true
      }

      return false
    }

    // Valores para los Richsnippets de Google
    let sum = 0
    let count = 0
    let best = 0
    services.forEach((service) => {
      const spa = spasByUuid[service.spaUUID]
      if (!spa) { return }
      if (!spa.score) { return }
      sum += spa.score
      count ++

      if (spa.score > best) {
        best = spa.score
      }
    })

    const rawScore = count ? (sum / count) : 0

    // NOTE: Recomendación de David Rodríguez. Si no ha nota que mostrar, ponemos 4 por defecto.
    const score = rawScore ? rawScore.toFixed(2) : '4'
    const maxScore = 5
    const reviewCount = Math.max(count, 1)

    return {
      title,
      subtitle,

      treatment,
      treatmentParent,
      location,

      // Richsnippets
      score,
      maxScore,
      reviewCount,

      // Filtros por tratamiento
      parentTreatments,
      childTreatments,
      isCurrentTreatmentOrParent,

      // Servicios
      servicesView,

      getSpaByUuid,

      // Sorter
      sortOptions,
      pushSortOrder,
      selectedSortOptionLabel,

      // Filtro de región
      countries,
      locationFilterBaseUrl,
      generalParentLocation,
      filterLocationsTree,
      paxLinkQueryParam,

      // Selector de personas
      selectedPax,
      hasSelectedPax,
      selectedPaxValue,
      addOneToPax,
      subOneToPax,

      // Mobile modal
      isModalDisplayed,
      setIsModalDisplayed,
    }
  }
})
</script>

<style lang="scss" scoped>
.treatment {
  @apply py-1;

  &__header {
    @apply px-2;
    @apply pb-2;

    @screen lg {
      @apply px-0;
    }
  }

  &__title {
    @apply mt-20p mb-20p;
    @apply text-xl;
    @apply text-spl-dark;
    @apply font-semibold;
    @apply text-left;

    @apply px-2;

    @screen lg {
      @apply text-3xl;
      @apply px-0;
    }
  }

  &__mobile-filters {
    &__header {
      &__title {
        @apply text-xl;
        @apply text-spl-dark;
        @apply py-4;
        @apply px-2;
        @apply font-semibold;
      }

      &__controls {
        @apply py-2;
        @apply flex justify-end;
      }
    }

    &__button {
      @apply flex items-center;
      @apply text-sm;

      @apply px-4;
      @apply py-2;
      @apply border-r-1;

      &__icon {
        @apply mr-1;
      }
    }

    &__expanded-filter {
      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__link {
        @apply pl-2;
        @apply py-1;

        @apply flex items-center;
        @apply gap-2;
      }

      &__link.current-treatment {
        @apply font-bold;
      }

      &__link:hover {
        @apply pl-4;
      }

      @apply pb-2 mb-2;
    }

    &__sort-selector {
      @apply text-sm;
      @apply px-4;
      @apply py-2;

      &__label {
        @apply font-bold;
        @apply italic;
      }
    }
  }

  &__filters {
    @apply py-4;
    @apply px-4;

    &__title {
      @apply text-lg;
      @apply font-bold;
      @apply text-spl-dark;

      @apply w-full;
      @apply py-1;
      @apply my-1;
      @apply border-b-1;
      @apply border-spl-secondary;
    }

    &__expanded-filter {
      @apply py-2;

      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__link {
        @apply pl-2;
        @apply py-1;

        @apply flex items-center;
        @apply gap-2;
      }

      &__link.current-treatment {
        @apply font-bold;
      }

      &__link:hover {
        @apply pl-4;
      }

      &__checkbox-node {

        input,
        label {
          @apply cursor-pointer;
        }

        label {
          @apply px-2;
          @apply text-spl-dark;
        }
      }

      &__checkbox-node:hover {
        label {
          @apply font-bold;
        }
      }

      &__checkbox-node.selected {
        label {
          @apply font-bold;
        }
      }
    }

    &__filter-selector {
      @apply my-2;
    }
  }

  &__list-wrapper {
    @apply mt-2;
    @apply py-4;

    @screen lg {
      @apply gap-3;
      @apply grid;
      grid-template-columns: 25% 75%;
    }
  }

  &__service-list {
    &__header {
      @apply hidden;

      @screen lg {
        @apply flex;
        @apply justify-between;
        @apply items-center;
      }

      @apply px-2 pb-1;

      &__subtitle {
        @apply text-spl-dark;
        @apply text-sm;
      }

      &__sort-selector {
        @apply my-2;

        &__label {
          @apply font-bold;
          @apply italic;
        }
      }
    }

    &__no-treatment {
      @apply text-center;
      @apply py-6;

      @apply mx-2;
      @apply my-8;

      @screen lg {
        @apply my-2;
      }

      @apply border;
      @apply border-spl-gray-1;
      border-radius: 16px;

      &__header {
        @apply font-bold;
        @apply text-spl-acent-dark;
      }
    }
  }
}
</style>
