
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/index.vue?macro=true";
import { default as index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta } from "/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue?macro=true";
export default [
  {
    name: index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta?.name ?? "index",
    path: index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta?.path ?? "/",
    props: index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta?.props ?? false,
    meta: index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta || {},
    alias: index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta?.alias || [],
    redirect: index6mRnAd0zaZ4UTtu8k0A4luPrRBUzUOdeH_45JFKOGJMmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/index.vue")
  },
  {
    name: index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta?.name ?? "lang",
    path: index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta?.path ?? "/:lang?",
    props: index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta?.props ?? false,
    meta: index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta || {},
    alias: index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta?.alias || [],
    redirect: index46upLXud0QMZbSFnxRHSToIrGaUxn4s_MxVeMU3f6JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta?.name ?? "lang-voucher-start",
    path: start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta?.path ?? "/:lang?/voucher/start",
    props: start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta?.props ?? false,
    meta: start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta || {},
    alias: start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta?.alias || [],
    redirect: start1ZJiVLKsUIjT6m_45u16cSN3POaPHX2NzMIeEctHcMJw0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta?.name ?? "lang-purchase-start",
    path: starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta?.path ?? "/:lang?/purchase/start",
    props: starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta?.props ?? false,
    meta: starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta || {},
    alias: starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta?.alias || [],
    redirect: starthpZNW5vGSM6TyDo7jAtCf3Ic18NrTyfxQu0icfQs6w8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta?.name ?? "lang-service-booking-checkout",
    path: checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta?.props ?? false,
    meta: checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta || {},
    alias: checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta?.alias || [],
    redirect: checkout5uU9SmRbrp5fXYgODqYdUgyNkt0_45_45UOw_advyryGDcAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta?.props ?? false,
    meta: kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta || {},
    alias: kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta?.alias || [],
    redirect: kol039aCcRH23v79ELToPyWIq0QjRnnz5wm1fWM1phbnMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta?.props ?? false,
    meta: confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta || {},
    alias: confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta?.alias || [],
    redirect: confirmationj1ov0pkFrJM7nHEEjoyT4Tr9q58w6DybypZ0gRwwvZEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta?.props ?? false,
    meta: confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta || {},
    alias: confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta?.alias || [],
    redirect: confirmationTzQrSEPQdOrtqHHwHenVaae0FUbTnFAgDzh_450VDrvf4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta?.props ?? false,
    meta: buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta || {},
    alias: buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta?.alias || [],
    redirect: buyer8ujxDnFiFG1Y_45zX_45Pb8EcWlzDo2XWShBxKEXo3c_EWUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta?.props ?? false,
    meta: buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta || {},
    alias: buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta?.alias || [],
    redirect: buyerE6Td8wRX73_45_olOloilvhkNiL1QM18CBjvr2I4HByxAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta?.props ?? false,
    meta: paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta || {},
    alias: paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta?.alias || [],
    redirect: paymentuFStGhDzy48D_W9joTku5cIhJp1YAdqBEWYea6N5a_458Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta?.props ?? false,
    meta: challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta || {},
    alias: challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta?.alias || [],
    redirect: challenge0OxWclgMDScLmi5pMCpQUGmX2lJ7P3q4ueDrudd0RfEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta?.props ?? false,
    meta: payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta || {},
    alias: payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta?.alias || [],
    redirect: payment2RF_45kEr_45m1geLdeY48eQZNBP0XB8tA6JtPv0uQ4M8T0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 0",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/a-coruna/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/a-coruna/carnota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/a-coruna/ferrol/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 5",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 6",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 7",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 8",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 9",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 10",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 11",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 12",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 13",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 14",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 15",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 16",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 17",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 18",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 19",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 20",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 21",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 22",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 23",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 24",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 25",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 26",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 27",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 28",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 29",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 30",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 31",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 32",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 33",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 34",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 35",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 36",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 37",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 38",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 39",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 40",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 41",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 42",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 43",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 44",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 45",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 46",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 47",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 48",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 49",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 50",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 51",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 52",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 53",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 54",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 55",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 56",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 57",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 58",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 59",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 60",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 61",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 62",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 63",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 64",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/granada/niguelas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 65",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 66",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 67",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 68",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 69",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 70",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 71",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 72",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 73",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 74",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 75",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 76",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 77",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 78",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 79",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 80",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 81",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 82",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 83",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 84",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 85",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 86",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 87",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 88",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 89",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 90",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 91",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 92",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 93",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/bano-turco-hammam/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 94",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 95",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 96",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/berga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 97",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 98",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 99",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 100",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 101",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 102",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 103",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 104",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 105",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 106",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 107",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/caceres/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 108",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 109",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 110",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 111",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/grazalema/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 112",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 113",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 114",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 115",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 116",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 117",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 118",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 119",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 120",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 121",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 122",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 123",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/castellon/montanejos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 124",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 125",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 126",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 127",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 128",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 129",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 130",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/a-coruna/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 131",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/a-coruna/carnota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 132",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/a-coruna/ferrol/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 133",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 134",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 135",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 136",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 137",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 138",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 139",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 140",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 141",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 142",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 143",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 144",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 145",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 146",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 147",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 148",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 149",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 150",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 151",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 152",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 153",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 154",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 155",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 156",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 157",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 158",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 159",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/berga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 160",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 161",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 162",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 163",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 164",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 165",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 166",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 167",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 168",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 169",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 170",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/caceres/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 171",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 172",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 173",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 174",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/grazalema/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 175",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 176",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 177",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 178",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 179",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 180",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 181",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 182",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 183",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 184",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 185",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 186",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/castellon/montanejos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 187",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 188",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 189",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 190",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 191",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 192",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 193",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 194",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 195",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cordoba/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 196",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 197",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 198",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 199",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 200",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 201",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 202",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 203",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 204",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 205",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 206",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 207",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 208",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 209",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 210",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 211",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 212",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 213",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/faro/monte-gordo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 214",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 215",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 216",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 217",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 218",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 219",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 220",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/caldes-de-malavella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 221",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/castello-dempuries/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 222",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 223",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 224",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 225",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 226",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/girona/toses/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 227",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 228",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/agaete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 229",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 230",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 231",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 232",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 233",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 234",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 235",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 236",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 237",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/granada/lanjaron/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 238",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 239",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 240",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 241",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 242",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 243",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 244",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 245",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 246",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 247",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 248",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 249",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 250",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 251",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 252",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/huesca/monzon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 253",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 254",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/jaen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 255",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/jaen/ubeda/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 256",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 257",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 258",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 259",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 260",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 261",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 262",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 263",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 264",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 265",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 266",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 267",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 268",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 269",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 270",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 271",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 272",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 273",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 274",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 275",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 276",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 277",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 278",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 279",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 280",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 281",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 282",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 283",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/benalmadena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 284",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 285",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 286",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 287",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 288",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 289",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 290",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 291",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 292",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 293",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 294",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/felanitx/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 295",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/llucmajor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 296",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/muro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 297",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 298",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 299",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 300",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 301",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 302",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/murcia/archena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 303",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/murcia/la-manga-del-mar-menor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 304",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 305",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 306",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 307",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 308",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 309",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 310",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/parroquia-de-escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 311",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 312",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 313",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 314",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/pontevedra/tui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 315",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 316",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 317",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 318",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 319",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 320",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/salamanca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 321",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/salamanca/bermellar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 322",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 323",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 324",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 325",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 326",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 327",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 328",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 329",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 330",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 331",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 332",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 333",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 334",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tarragona/torroja-del-priorat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 335",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 336",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 337",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 338",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 339",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/playa-de-las-americas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 340",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 341",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 342",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 343",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 344",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 345",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 346",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 347",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 348",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 349",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 350",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 351",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 352",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 353",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 354",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 355",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 356",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 357",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 358",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 359",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/vila-real/ribeira-de-pena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 360",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 361",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/circuito-termal/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 362",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 363",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 364",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 365",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 366",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cordoba/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 367",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 368",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 369",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 370",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 371",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 372",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 373",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 374",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 375",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 376",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 377",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 378",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 379",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 380",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 381",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 382",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 383",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 384",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 385",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 386",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 387",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 388",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 389",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 390",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 391",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 392",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 393",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 394",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 395",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 396",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 397",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 398",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 399",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 400",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 401",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 402",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 403",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 404",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 405",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 406",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 407",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 408",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 409",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 410",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 411",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 412",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 413",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 414",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 415",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/duchas-terapeuticas/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 416",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 417",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 418",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 419",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 420",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 421",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 422",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 423",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 424",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/faro/monte-gordo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 425",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 426",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 427",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 428",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 429",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 430",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 431",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/caldes-de-malavella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 432",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/castello-dempuries/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 433",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 434",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 435",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 436",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 437",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/girona/toses/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 438",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 439",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/agaete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 440",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 441",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 442",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 443",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 444",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 445",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 446",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 447",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 448",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/lanjaron/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 449",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 450",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 451",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 452",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/granada/niguelas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 453",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 454",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 455",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 456",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 457",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 458",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 459",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 460",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 461",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 462",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 463",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 464",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 465",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 466",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 467",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 468",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 469",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 470",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 471",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 472",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 473",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 474",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 475",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/castellon/montanejos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 476",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 477",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 478",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 479",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 480",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 481",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 482",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 483",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 484",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 485",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 486",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 487",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 488",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 489",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 490",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 491",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 492",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 493",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 494",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 495",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 496",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 497",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 498",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 499",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/granada/niguelas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 500",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 501",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 502",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 503",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 504",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 505",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 506",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 507",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 508",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 509",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 510",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 511",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 512",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 513",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 514",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 515",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 516",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 517",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 518",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 519",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 520",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 521",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 522",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 523",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 524",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 525",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 526",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 527",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 528",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 529",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 530",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 531",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 532",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 533",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 534",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 535",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 536",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 537",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 538",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 539",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 540",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 541",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/vila-real/ribeira-de-pena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 542",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 543",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/hidromasaje/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 544",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 545",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 546",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 547",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 548",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 549",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 550",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 551",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/huesca/monzon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 552",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 553",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/jaen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 554",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/jaen/ubeda/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 555",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 556",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 557",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 558",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 559",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 560",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 561",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 562",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 563",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 564",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 565",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 566",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 567",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 568",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 569",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 570",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 571",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 572",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 573",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 574",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 575",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 576",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 577",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 578",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 579",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 580",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 581",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 582",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/benalmadena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 583",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 584",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 585",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 586",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 587",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 588",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 589",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 590",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 591",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 592",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 593",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/felanitx/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 594",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/llucmajor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 595",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/muro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 596",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 597",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 598",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 599",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 600",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 601",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/murcia/archena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 602",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/murcia/la-manga-del-mar-menor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 603",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 604",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 605",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 606",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 607",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 608",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 609",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/parroquia-de-escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 610",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 611",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 612",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 613",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/pontevedra/tui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 614",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 615",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 616",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 617",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 618",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 619",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/salamanca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 620",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/salamanca/bermellar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 621",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 622",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 623",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 624",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 625",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 626",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 627",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 628",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 629",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 630",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 631",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 632",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 633",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 634",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 635",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 636",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 637",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 638",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 639",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 640",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 641",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 642",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 643",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 644",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 645",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 646",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 647",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 648",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 649",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 650",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 651",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 652",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 653",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 654",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 655",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 656",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 657",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 658",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 659",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 660",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 661",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 662",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 663",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 664",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 665",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 666",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 667",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 668",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 669",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 670",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 671",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 672",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 673",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sauna-finlandesa/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 674",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 675",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 676",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 677",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 678",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 679",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 680",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 681",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 682",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 683",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 684",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 685",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 686",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 687",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 688",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 689",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 690",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/talasoterapia/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 691",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 692",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 693",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 694",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 695",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tarragona/torroja-del-priorat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 696",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 697",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 698",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 699",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 700",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/playa-de-las-americas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 701",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 702",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 703",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 704",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 705",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 706",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 707",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 708",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 709",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 710",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 711",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 712",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 713",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 714",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 715",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 716",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 717",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 718",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 719",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 720",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/vila-real/ribeira-de-pena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 721",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 722",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/hidroterapia/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 723",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 724",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 725",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/a-coruna/carnota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 726",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 727",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 728",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 729",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 730",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 731",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 732",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 733",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 734",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 735",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 736",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 737",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 738",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 739",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 740",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 741",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 742",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 743",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 744",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 745",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 746",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 747",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 748",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 749",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 750",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 751",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 752",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/berga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 753",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 754",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 755",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 756",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 757",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 758",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 759",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 760",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 761",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 762",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 763",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/caceres/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 764",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 765",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 766",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 767",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/grazalema/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 768",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 769",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 770",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 771",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 772",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 773",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 774",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 775",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 776",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 777",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 778",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 779",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 780",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 781",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 782",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 783",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 784",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 785",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 786",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 787",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 788",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 789",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 790",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 791",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 792",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 793",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 794",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 795",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 796",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 797",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 798",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 799",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 800",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 801",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 802",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 803",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 804",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 805",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 806",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 807",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 808",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 809",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 810",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 811",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 812",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 813",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 814",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 815",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 816",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 817",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 818",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 819",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 820",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 821",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 822",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 823",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 824",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 825",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 826",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 827",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 828",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 829",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 830",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 831",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 832",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 833",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 834",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 835",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 836",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/chocolaterapia/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 837",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 838",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 839",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 840",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 841",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 842",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 843",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 844",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 845",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 846",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 847",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 848",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 849",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 850",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 851",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 852",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 853",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 854",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 855",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 856",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 857",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 858",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/faro/monte-gordo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 859",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 860",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 861",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 862",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 863",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 864",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 865",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/caldes-de-malavella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 866",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/castello-dempuries/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 867",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 868",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 869",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 870",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 871",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/girona/toses/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 872",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 873",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/agaete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 874",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 875",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 876",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 877",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 878",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 879",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 880",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 881",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 882",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 883",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 884",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 885",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/granada/niguelas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 886",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 887",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/guadalajara/abanades/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 888",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 889",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 890",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 891",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 892",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 893",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 894",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 895",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 896",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 897",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 898",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 899",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 900",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 901",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/jaen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 902",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/jaen/ubeda/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 903",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 904",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 905",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 906",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 907",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 908",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leiria/alcobaca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 909",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 910",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 911",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 912",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 913",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 914",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 915",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 916",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 917",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 918",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 919",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 920",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 921",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 922",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 923",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 924",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 925",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 926",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 927",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 928",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 929",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 930",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 931",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 932",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 933",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 934",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 935",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/benalmadena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 936",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 937",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 938",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 939",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 940",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 941",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 942",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 943",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 944",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 945",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 946",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/felanitx/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 947",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/llucmajor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 948",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/muro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 949",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 950",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 951",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 952",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 953",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 954",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 955",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 956",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 957",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 958",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 959",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 960",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 961",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 962",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 963",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 964",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 965",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 966",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 967",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 968",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 969",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 970",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 971",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 972",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 973",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 974",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 975",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 976",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 977",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 978",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 979",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 980",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 981",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 982",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 983",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 984",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 985",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 986",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 987",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 988",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 989",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 990",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 991",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 992",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 993",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 994",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 995",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 996",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 997",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 998",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 999",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1000",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1001",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1002",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1003",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1004",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1005",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1006",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1007",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1008",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1009",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1010",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-aceite/vila-real/ribeira-de-pena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1011",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1012",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1013",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1014",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1015",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1016",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1017",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1018",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1019",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1020",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1021",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1022",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1023",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1024",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1025",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1026",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1027",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1028",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-anticelulitico/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1029",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1030",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1031",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1032",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1033",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1034",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1035",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1036",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1037",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1038",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1039",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1040",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1041",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1042",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1043",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1044",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1045",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1046",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1047",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1048",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1049",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1050",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1051",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1052",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1053",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1054",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1055",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1056",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1057",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1058",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1059",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1060",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1061",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1062",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1063",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1064",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1065",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1066",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1067",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1068",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1069",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1070",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1071",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1072",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1073",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1074",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1075",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1076",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1077",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1078",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1079",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1080",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1081",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1082",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1083",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1084",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1085",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1086",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1087",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1088",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1089",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1090",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1091",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-ayurveda/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1092",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1093",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1094",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1095",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1096",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1097",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1098",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1099",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1100",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1101",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1102",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1103",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1104",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1105",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1106",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1107",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1108",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1109",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1110",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1111",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1112",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1113",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1114",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1115",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1116",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1117",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1118",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1119",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1120",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-circulatorio/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1121",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1122",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1123",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1124",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1125",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1126",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1127",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1128",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1129",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1130",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1131",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1132",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1133",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1134",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1135",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1136",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1137",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1138",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1139",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1140",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1141",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1142",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1143",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1144",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1145",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1146",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1147",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1148",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1149",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1150",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1151",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1152",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-esencias/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1153",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1154",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1155",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1156",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1157",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1158",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1159",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1160",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1161",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1162",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1163",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1164",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1165",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1166",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1167",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1168",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1169",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1170",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1171",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1172",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1173",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1174",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1175",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1176",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1177",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1178",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1179",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1180",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1181",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1182",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1183",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1184",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1185",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1186",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1187",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1188",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-con-velas/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1189",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1190",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1191",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1192",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1193",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1194",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1195",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1196",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1197",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1198",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1199",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1200",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1201",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1202",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1203",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1204",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1205",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1206",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1207",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1208",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1209",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1210",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1211",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1212",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1213",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1214",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1215",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1216",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1217",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1218",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1219",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1220",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1221",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1222",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1223",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1224",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1225",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1226",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1227",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1228",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1229",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1230",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1231",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1232",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1233",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1234",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1235",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1236",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1237",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1238",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1239",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1240",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1241",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1242",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1243",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1244",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1245",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1246",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1247",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1248",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1249",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-craneofacial/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1250",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1251",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1252",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1253",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1254",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1255",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1256",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1257",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1258",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1259",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1260",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1261",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1262",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1263",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1264",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1265",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1266",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1267",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1268",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1269",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1270",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1271",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1272",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1273",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1274",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-cuatro-manos/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1275",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1276",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1277",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1278",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1279",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1280",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1281",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-manos/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1282",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1283",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1284",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1285",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1286",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1287",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1288",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1289",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1290",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1291",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1292",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1293",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1294",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1295",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1296",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1297",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1298",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1299",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1300",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1301",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1302",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1303",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1304",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1305",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1306",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1307",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1308",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1309",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1310",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1311",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1312",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1313",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1314",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1315",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1316",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1317",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1318",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1319",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1320",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1321",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1322",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1323",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1324",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1325",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1326",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1327",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pies/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1328",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1329",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1330",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1331",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1332",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1333",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1334",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1335",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1336",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1337",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1338",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1339",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1340",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1341",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1342",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1343",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1344",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1345",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1346",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1347",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1348",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1349",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1350",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1351",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1352",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1353",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1354",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1355",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1356",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1357",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1358",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1359",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1360",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-de-pindas/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1361",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1362",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1363",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1364",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1365",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1366",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1367",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1368",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1369",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1370",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1371",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1372",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1373",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1374",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1375",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1376",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1377",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1378",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1379",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1380",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1381",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1382",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1383",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1384",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1385",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1386",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1387",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1388",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1389",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1390",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1391",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1392",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1393",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1394",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1395",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1396",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1397",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/mallorca/felanitx/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1398",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1399",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1400",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1401",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1402",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1403",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1404",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1405",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1406",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-deportivo/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1407",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1408",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1409",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1410",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1411",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1412",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1413",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1414",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1415",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1416",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1417",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1418",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1419",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1420",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1421",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1422",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1423",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1424",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1425",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1426",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1427",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1428",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1429",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1430",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1431",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1432",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1433",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1434",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1435",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1436",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1437",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1438",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1439",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1440",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1441",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1442",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1443",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1444",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1445",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1446",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1447",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1448",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1449",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1450",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1451",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1452",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1453",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1454",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1455",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1456",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1457",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1458",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1459",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1460",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1461",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1462",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1463",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1464",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1465",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1466",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1467",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1468",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1469",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1470",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1471",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1472",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1473",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1474",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1475",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1476",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1477",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1478",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1479",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1480",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1481",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1482",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1483",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1484",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1485",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1486",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1487",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1488",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1489",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1490",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1491",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1492",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1493",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1494",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1495",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1496",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1497",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-descontracturante/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1498",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1499",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1500",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1501",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1502",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1503",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1504",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1505",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1506",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1507",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1508",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1509",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1510",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1511",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1512",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1513",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1514",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1515",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1516",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1517",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1518",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1519",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-estimulante/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1520",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1521",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1522",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1523",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1524",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1525",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1526",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1527",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1528",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-exotico/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1529",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1530",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1531",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1532",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1533",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1534",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1535",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1536",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1537",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1538",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1539",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1540",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1541",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1542",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1543",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1544",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1545",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1546",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1547",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1548",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1549",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1550",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1551",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1552",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1553",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1554",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1555",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1556",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1557",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1558",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial-con-mascarilla/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1559",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1560",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1561",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1562",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1563",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1564",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1565",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1566",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1567",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1568",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1569",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1570",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1571",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1572",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1573",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1574",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1575",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1576",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1577",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1578",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1579",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1580",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1581",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1582",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1583",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1584",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1585",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1586",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1587",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1588",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1589",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1590",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1591",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1592",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1593",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1594",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1595",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1596",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1597",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1598",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1599",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1600",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1601",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1602",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1603",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1604",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1605",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1606",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1607",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1608",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1609",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1610",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1611",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1612",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1613",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-facial/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1614",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1615",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1616",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1617",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1618",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1619",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1620",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1621",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1622",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1623",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1624",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1625",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1626",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1627",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1628",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1629",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1630",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1631",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1632",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1633",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1634",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1635",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1636",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1637",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1638",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1639",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1640",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1641",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1642",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1643",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1644",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1645",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1646",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1647",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1648",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1649",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1650",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1651",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1652",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1653",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1654",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1655",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1656",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-hidratante/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1657",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1658",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1659",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1660",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1661",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1662",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1663",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1664",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1665",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1666",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1667",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1668",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1669",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1670",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1671",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1672",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1673",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1674",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1675",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1676",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1677",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1678",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1679",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1680",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1681",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-holistico/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1682",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1683",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1684",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1685",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1686",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1687",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1688",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1689",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1690",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1691",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1692",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1693",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1694",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1695",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1696",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1697",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1698",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1699",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1700",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1701",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1702",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1703",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1704",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1705",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1706",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1707",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1708",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1709",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1710",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1711",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1712",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1713",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1714",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1715",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1716",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1717",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1718",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1719",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1720",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1721",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1722",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1723",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1724",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1725",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1726",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/girona/castello-dempuries/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1727",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1728",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1729",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1730",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1731",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1732",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1733",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1734",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1735",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1736",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1737",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1738",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1739",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1740",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1741",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1742",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1743",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1744",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1745",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1746",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1747",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1748",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1749",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1750",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1751",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1752",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1753",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1754",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1755",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1756",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1757",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1758",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1759",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1760",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1761",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1762",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1763",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1764",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1765",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1766",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1767",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-localizado/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1768",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1769",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1770",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1771",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1772",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1773",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1774",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1775",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1776",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1777",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1778",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1779",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1780",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1781",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1782",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1783",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1784",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1785",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1786",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1787",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1788",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1789",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1790",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1791",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1792",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1793",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1794",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1795",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1796",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1797",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1798",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1799",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1800",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1801",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1802",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1803",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1804",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1805",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1806",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/mallorca/muro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1807",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1808",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1809",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1810",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1811",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1812",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1813",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1814",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1815",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1816",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1817",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1818",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1819",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1820",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-lomi-lomi/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1821",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1822",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1823",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1824",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1825",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1826",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1827",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1828",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1829",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1830",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1831",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1832",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1833",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1834",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1835",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1836",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1837",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1838",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1839",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1840",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1841",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1842",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1843",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1844",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1845",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1846",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1847",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1848",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1849",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1850",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1851",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1852",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1853",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1854",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1855",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1856",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1857",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1858",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1859",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1860",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1861",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1862",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1863",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1864",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1865",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1866",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1867",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1868",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1869",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1870",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1871",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1872",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1873",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1874",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1875",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1876",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1877",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1878",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1879",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1880",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1881",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-los-tejidos-profundos/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1882",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1883",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1884",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1885",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1886",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1887",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1888",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1889",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1890",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1891",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1892",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1893",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1894",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/caceres/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1895",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1896",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1897",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1898",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1899",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1900",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1901",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1902",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1903",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1904",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1905",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1906",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1907",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1908",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1909",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1910",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1911",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1912",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1913",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1914",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1915",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1916",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1917",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1918",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1919",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1920",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1921",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1922",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1923",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1924",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1925",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1926",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1927",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1928",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1929",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1930",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1931",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1932",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1933",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1934",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1935",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-oriental/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1936",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1937",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1938",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1939",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1940",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1941",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1942",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1943",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1944",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1945",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1946",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1947",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1948",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1949",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/granada/niguelas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1950",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1951",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1952",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1953",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1954",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1955",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1956",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1957",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1958",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1959",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1960",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1961",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1962",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1963",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1964",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1965",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1966",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1967",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-personalizado/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1968",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1969",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1970",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1971",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1972",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1973",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1974",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1975",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1976",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1977",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1978",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1979",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1980",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1981",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1982",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1983",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1984",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1985",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1986",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1987",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1988",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1989",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1990",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1991",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1992",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1993",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1994",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1995",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1996",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1997",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1998",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 1999",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2000",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2001",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2002",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2003",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2004",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2005",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2006",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2007",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2008",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2009",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2010",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2011",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2012",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2013",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2014",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2015",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2016",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2017",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2018",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2019",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2020",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2021",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2022",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2023",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2024",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2025",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2026",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2027",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2028",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2029",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2030",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2031",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2032",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2033",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2034",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2035",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2036",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2037",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2038",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2039",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2040",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2041",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2042",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2043",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2044",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2045",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2046",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2047",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2048",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2049",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2050",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2051",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2052",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2053",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2054",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2055",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2056",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2057",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2058",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2059",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2060",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2061",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2062",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2063",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2064",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2065",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2066",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2067",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2068",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2069",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2070",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2071",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2072",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2073",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2074",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2075",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2076",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2077",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2078",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2079",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2080",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2081",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2082",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2083",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2084",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2085",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/playa-de-las-americas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2086",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2087",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2088",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2089",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/toledo/carranque/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2090",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2091",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2092",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2093",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2094",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2095",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2096",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2097",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-piedras-calientes/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2098",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2099",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2100",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2101",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2102",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2103",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2104",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2105",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2106",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2107",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2108",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2109",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2110",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2111",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2112",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2113",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2114",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2115",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2116",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2117",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2118",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2119",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2120",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2121",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2122",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2123",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2124",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2125",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2126",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2127",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2128",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2129",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2130",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2131",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2132",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2133",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2134",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2135",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2136",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2137",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2138",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2139",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2140",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2141",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2142",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2143",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2144",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2145",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2146",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2147",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2148",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2149",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2150",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-prenatal/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2151",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2152",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2153",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/a-coruna/carnota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2154",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2155",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2156",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2157",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2158",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2159",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2160",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2161",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2162",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2163",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2164",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2165",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2166",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2167",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2168",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2169",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2170",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2171",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2172",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2173",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2174",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2175",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2176",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2177",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2178",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2179",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/berga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2180",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2181",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2182",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2183",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2184",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2185",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2186",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2187",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2188",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2189",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2190",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2191",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2192",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2193",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2194",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2195",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2196",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2197",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2198",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2199",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2200",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2201",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2202",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2203",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2204",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2205",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2206",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2207",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2208",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2209",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2210",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2211",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2212",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2213",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2214",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2215",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2216",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2217",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2218",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2219",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2220",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2221",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2222",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2223",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2224",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2225",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2226",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2227",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/faro/monte-gordo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2228",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2229",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2230",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2231",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2232",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2233",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2234",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2235",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2236",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2237",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/girona/toses/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2238",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2239",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/gran-canaria/agaete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2240",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2241",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2242",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2243",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2244",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2245",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2246",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2247",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2248",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2249",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2250",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/granada/niguelas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2251",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2252",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2253",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2254",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2255",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2256",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2257",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2258",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2259",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2260",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2261",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2262",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2263",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2264",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2265",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2266",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2267",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2268",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2269",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leiria/alcobaca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2270",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2271",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2272",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leiria/vestiaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2273",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2274",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2275",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2276",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2277",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2278",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2279",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2280",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2281",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2282",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2283",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2284",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2285",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2286",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2287",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2288",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2289",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2290",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2291",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2292",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2293",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2294",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/benalmadena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2295",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2296",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2297",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2298",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2299",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2300",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2301",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2302",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2303",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/felanitx/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2304",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/llucmajor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2305",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/muro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2306",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2307",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2308",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2309",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2310",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2311",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/murcia/archena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2312",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2313",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2314",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2315",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2316",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2317",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2318",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2319",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/parroquia-de-escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2320",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2321",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2322",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2323",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2324",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2325",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2326",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2327",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2328",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2329",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2330",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2331",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2332",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2333",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2334",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2335",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2336",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2337",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2338",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2339",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tarragona/torroja-del-priorat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2340",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2341",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2342",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2343",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2344",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/playa-de-las-americas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2345",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2346",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2347",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2348",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2349",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2350",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2351",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2352",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2353",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2354",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2355",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2356",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2357",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2358",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2359",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2360",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2361",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2362",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2363",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/vila-real/ribeira-de-pena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2364",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2365",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-relajante/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2366",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2367",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2368",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2369",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2370",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2371",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2372",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2373",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2374",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2375",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2376",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2377",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2378",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2379",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2380",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2381",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2382",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2383",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2384",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2385",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2386",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2387",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2388",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2389",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-shiatsu/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2390",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2391",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2392",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/alicante/altea/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2393",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2394",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2395",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2396",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2397",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2398",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/cadiz/novo-sancti-petri/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2399",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2400",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2401",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2402",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2403",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2404",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2405",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2406",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2407",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2408",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2409",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2410",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2411",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2412",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2413",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2414",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-sueco/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2415",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2416",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2417",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2418",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2419",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2420",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2421",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2422",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2423",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2424",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2425",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2426",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2427",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2428",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2429",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2430",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2431",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2432",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2433",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2434",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2435",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2436",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2437",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2438",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2439",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2440",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2441",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-tailandes/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2442",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2443",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2444",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2445",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2446",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2447",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2448",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2449",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2450",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2451",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2452",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2453",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2454",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2455",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2456",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2457",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2458",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2459",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2460",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2461",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2462",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2463",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2464",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2465",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2466",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/faro/monte-gordo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2467",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2468",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2469",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2470",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2471",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2472",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2473",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2474",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2475",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2476",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2477",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2478",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2479",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2480",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2481",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2482",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2483",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2484",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2485",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2486",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2487",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2488",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2489",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2490",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2491",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2492",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2493",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2494",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2495",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2496",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2497",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2498",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2499",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2500",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2501",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2502",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2503",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2504",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2505",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2506",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2507",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2508",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2509",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2510",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2511",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2512",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/masaje-terapeutico/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2513",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2514",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/murcia/archena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2515",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2516",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2517",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2518",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2519",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2520",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2521",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2522",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2523",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2524",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2525",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2526",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2527",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2528",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2529",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2530",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2531",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2532",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2533",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2534",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2535",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2536",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2537",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2538",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2539",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2540",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2541",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/caldes-de-montbui/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2542",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2543",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2544",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2545",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2546",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2547",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2548",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2549",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2550",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2551",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/grazalema/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2552",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2553",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2554",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2555",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2556",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2557",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2558",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2559",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2560",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2561",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2562",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2563",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2564",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2565",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2566",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2567",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2568",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2569",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2570",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2571",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2572",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2573",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/girona/caldes-de-malavella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2574",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2575",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2576",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2577",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/gran-canaria/agaete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2578",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2579",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2580",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2581",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2582",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2583",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2584",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2585",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2586",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/guadalajara/abanades/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2587",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2588",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2589",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2590",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2591",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2592",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2593",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2594",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2595",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2596",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2597",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2598",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/jaen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2599",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/jaen/ubeda/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2600",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2601",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2602",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2603",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2604",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2605",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2606",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2607",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2608",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2609",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2610",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2611",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2612",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2613",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2614",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2615",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2616",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2617",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2618",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2619",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2620",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2621",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2622",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2623",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2624",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2625",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2626",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2627",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2628",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2629",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2630",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2631",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2632",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2633",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2634",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2635",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2636",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2637",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2638",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2639",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2640",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2641",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2642",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2643",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2644",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2645",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2646",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/otros-masajes/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2647",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2648",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/parroquia-de-escaldes-engordany/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2649",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2650",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2651",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2652",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2653",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2654",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2655",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2656",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2657",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2658",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2659",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2660",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2661",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2662",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2663",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2664",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2665",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2666",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2667",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2668",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2669",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2670",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2671",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2672",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2673",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2674",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2675",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2676",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2677",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2678",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2679",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2680",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2681",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2682",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2683",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2684",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2685",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2686",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2687",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2688",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2689",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2690",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2691",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2692",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2693",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2694",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2695",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2696",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/lleida/vielha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2697",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2698",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2699",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2700",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2701",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2702",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2703",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2704",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2705",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2706",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2707",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2708",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2709",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2710",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2711",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2712",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2713",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2714",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2715",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2716",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2717",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2718",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2719",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/reflexologia-podal/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2720",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2721",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2722",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2723",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2724",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2725",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/setubal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2726",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/setubal/santiago-do-cacem/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2727",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2728",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2729",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2730",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2731",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2732",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2733",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2734",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2735",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tarragona/torroja-del-priorat/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2736",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2737",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2738",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2739",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2740",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/playa-de-las-americas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2741",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2742",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2743",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2744",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2745",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2746",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/toledo/carranque/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2747",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2748",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2749",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2750",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2751",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2752",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2753",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2754",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2755",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2756",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2757",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2758",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2759",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2760",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2761",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2762",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/vila-real/ribeira-de-pena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2763",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2764",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/masajes/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2765",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/otros-tratamientos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2766",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2767",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2768",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2769",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alava/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2770",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alava/laguardia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2771",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2772",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2773",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2774",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2775",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2776",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2777",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2778",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2779",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2780",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2781",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2782",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2783",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2784",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2785",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2786",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2787",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2788",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2789",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2790",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2791",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2792",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2793",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2794",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2795",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2796",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2797",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2798",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2799",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2800",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2801",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2802",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2803",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2804",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2805",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2806",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2807",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2808",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2809",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2810",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2811",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2812",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2813",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2814",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2815",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2816",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2817",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2818",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2819",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2820",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2821",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2822",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2823",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2824",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2825",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2826",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2827",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2828",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2829",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2830",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2831",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2832",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2833",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2834",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2835",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2836",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2837",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2838",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2839",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2840",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2841",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2842",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2843",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2844",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2845",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2846",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2847",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2848",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2849",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2850",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2851",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2852",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2853",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2854",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2855",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2856",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2857",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cantabria/medio-cudeyo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2858",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2859",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2860",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2861",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2862",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2863",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2864",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2865",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2866",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2867",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2868",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2869",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2870",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2871",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2872",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2873",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2874",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2875",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2876",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2877",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2878",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2879",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2880",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2881",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2882",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2883",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2884",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2885",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2886",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2887",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2888",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2889",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2890",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2891",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2892",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2893",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2894",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2895",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2896",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2897",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2898",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2899",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2900",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2901",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2902",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2903",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2904",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2905",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2906",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2907",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2908",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2909",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2910",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2911",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2912",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2913",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2914",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2915",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2916",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2917",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2918",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2919",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2920",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2921",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2922",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2923",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2924",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2925",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2926",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2927",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2928",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2929",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2930",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2931",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2932",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2933",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2934",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2935",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2936",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2937",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2938",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2939",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2940",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2941",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2942",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2943",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2944",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2945",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2946",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2947",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2948",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2949",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2950",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2951",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2952",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/envoltura-corporal/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2953",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2954",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2955",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2956",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2957",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2958",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2959",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2960",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2961",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2962",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2963",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2964",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2965",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2966",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2967",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2968",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2969",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2970",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2971",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2972",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2973",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2974",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2975",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2976",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2977",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2978",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2979",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2980",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2981",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2982",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2983",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2984",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2985",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2986",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2987",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2988",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2989",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2990",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2991",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2992",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lanzarote/teguise/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2993",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2994",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2995",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2996",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2997",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2998",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 2999",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3000",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3001",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3002",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3003",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3004",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3005",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3006",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3007",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3008",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3009",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3010",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3011",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3012",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3013",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3014",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3015",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3016",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3017",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3018",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3019",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3020",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3021",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3022",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3023",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3024",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3025",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3026",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/llucmajor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3027",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3028",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3029",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3030",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3031",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3032",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3033",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3034",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3035",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3036",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3037",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3038",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3039",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3040",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3041",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3042",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alava/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3043",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alava/laguardia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3044",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3045",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3046",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3047",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3048",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3049",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3050",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3051",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3052",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3053",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3054",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3055",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3056",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3057",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3058",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3059",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3060",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3061",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3062",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3063",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3064",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3065",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3066",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3067",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3068",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3069",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3070",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3071",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3072",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3073",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3074",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3075",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3076",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3077",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3078",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3079",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3080",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3081",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3082",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3083",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3084",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3085",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3086",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3087",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3088",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3089",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3090",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3091",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3092",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3093",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3094",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3095",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3096",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3097",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3098",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3099",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3100",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3101",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3102",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3103",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3104",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3105",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3106",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3107",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3108",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/mallorca/llucmajor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3109",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3110",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3111",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3112",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3113",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3114",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3115",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3116",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3117",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3118",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3119",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3120",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3121",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3122",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3123",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3124",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3125",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3126",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3127",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3128",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3129",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3130",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3131",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3132",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3133",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3134",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/otros-rituales/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3135",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3136",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3137",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3138",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3139",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3140",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3141",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3142",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3143",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3144",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3145",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3146",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3147",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3148",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3149",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3150",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3151",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3152",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3153",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3154",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3155",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3156",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3157",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3158",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3159",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3160",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3161",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3162",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3163",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3164",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3165",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3166",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3167",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3168",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3169",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3170",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3171",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-contorno-ojos/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3172",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3173",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3174",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3175",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3176",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3177",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3178",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3179",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3180",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3181",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3182",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3183",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3184",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3185",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3186",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3187",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3188",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3189",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3190",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3191",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3192",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3193",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3194",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3195",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3196",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3197",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3198",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3199",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3200",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3201",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3202",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3203",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3204",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3205",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3206",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3207",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3208",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3209",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3210",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3211",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3212",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3213",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3214",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3215",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3216",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3217",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3218",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3219",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3220",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3221",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3222",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3223",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3224",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3225",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3226",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3227",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3228",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3229",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3230",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3231",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3232",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3233",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3234",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3235",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3236",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3237",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3238",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3239",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3240",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3241",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3242",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3243",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3244",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3245",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3246",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3247",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3248",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3249",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3250",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3251",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3252",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3253",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3254",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3255",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3256",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3257",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3258",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3259",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3260",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3261",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3262",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3263",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3264",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3265",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3266",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3267",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3268",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3269",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3270",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3271",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3272",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3273",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3274",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3275",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3276",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3277",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3278",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3279",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3280",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3281",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3282",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3283",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3284",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3285",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3286",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3287",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3288",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3289",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3290",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3291",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3292",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3293",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3294",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3295",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3296",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3297",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3298",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3299",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3300",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3301",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3302",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3303",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3304",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3305",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3306",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3307",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3308",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3309",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3310",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3311",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3312",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3313",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3314",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3315",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3316",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3317",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3318",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3319",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3320",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3321",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3322",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3323",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3324",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3325",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3326",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3327",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3328",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3329",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3330",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3331",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3332",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/tratamiento-facial/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3333",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3334",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3335",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3336",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3337",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3338",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3339",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3340",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3341",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/velo-de-colageno/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3342",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/velo-de-colageno/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3343",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/velo-de-colageno/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3344",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/velo-de-colageno/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3345",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3346",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3347",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3348",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3349",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3350",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3351",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3352",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3353",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3354",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3355",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3356",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3357",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/rituales-belleza/vinoterapia/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3358",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3359",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3360",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3361",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3362",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3363",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3364",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3365",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3366",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3367",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3368",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3369",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3370",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3371",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3372",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3373",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3374",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3375",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3376",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3377",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3378",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3379",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3380",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3381",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3382",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3383",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3384",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3385",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3386",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3387",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3388",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3389",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3390",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3391",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3392",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3393",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3394",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3395",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3396",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3397",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3398",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3399",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3400",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3401",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3402",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3403",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3404",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3405",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3406",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3407",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3408",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3409",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3410",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3411",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3412",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3413",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3414",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3415",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3416",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3417",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3418",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3419",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3420",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3421",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3422",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3423",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3424",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3425",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3426",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3427",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3428",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3429",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3430",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3431",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3432",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3433",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3434",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3435",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3436",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3437",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3438",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3439",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3440",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3441",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3442",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3443",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3444",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3445",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3446",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3447",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3448",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3449",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3450",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3451",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3452",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3453",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3454",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3455",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3456",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3457",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3458",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3459",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3460",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3461",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3462",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3463",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3464",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3465",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3466",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3467",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3468",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3469",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3470",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3471",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3472",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3473",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3474",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3475",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3476",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3477",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3478",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3479",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/aromaterapia/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3480",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3481",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3482",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3483",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3484",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3485",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3486",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3487",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3488",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3489",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3490",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3491",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3492",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3493",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3494",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3495",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3496",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/caceres/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3497",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3498",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3499",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3500",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3501",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3502",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3503",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3504",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3505",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3506",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3507",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3508",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3509",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3510",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3511",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3512",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3513",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3514",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3515",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3516",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3517",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3518",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3519",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3520",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3521",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3522",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3523",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3524",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3525",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/alicante/teulada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3526",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3527",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3528",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3529",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3530",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3531",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3532",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3533",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3534",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3535",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3536",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3537",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3538",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3539",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3540",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3541",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3542",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3543",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3544",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3545",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3546",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3547",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3548",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3549",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3550",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3551",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3552",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3553",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3554",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3555",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3556",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3557",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/drenaje-linfatico/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3558",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3559",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3560",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3561",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3562",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3563",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3564",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3565",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3566",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3567",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3568",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3569",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3570",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3571",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3572",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3573",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3574",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3575",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3576",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3577",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3578",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3579",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3580",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3581",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3582",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3583",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/granada/monachil/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3584",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3585",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/guadalajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3586",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/guadalajara/brihuega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3587",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3588",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3589",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3590",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3591",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3592",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3593",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3594",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3595",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3596",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3597",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3598",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3599",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3600",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3601",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3602",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3603",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3604",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3605",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3606",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lisboa/linda-a-velha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3607",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3608",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3609",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3610",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3611",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lleida/caldes-de-boi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3612",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3613",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3614",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3615",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3616",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3617",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3618",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3619",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3620",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3621",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3622",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3623",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3624",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3625",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3626",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3627",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3628",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3629",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3630",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3631",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3632",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3633",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3634",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3635",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3636",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3637",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3638",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3639",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3640",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3641",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3642",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3643",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3644",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3645",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3646",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3647",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3648",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3649",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/alicante/benidorm/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3650",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3651",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/alicante/l-alfas-del-pi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3652",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3653",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3654",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3655",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3656",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3657",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3658",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3659",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3660",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3661",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3662",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3663",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3664",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3665",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3666",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/barcelona/vallromanes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3667",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3668",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3669",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3670",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/caceres/caceres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3671",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3672",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3673",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3674",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3675",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3676",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3677",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3678",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cantabria/la-hermida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3679",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3680",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3681",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3682",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3683",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3684",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3685",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3686",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3687",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3688",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3689",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3690",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3691",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3692",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3693",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3694",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3695",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3696",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3697",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3698",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3699",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/faro/monchique/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3700",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3701",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3702",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3703",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3704",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3705",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3706",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3707",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3708",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3709",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3710",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3711",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3712",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3713",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3714",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3715",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3716",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3717",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3718",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3719",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3720",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3721",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3722",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3723",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/huelva/punta-umbria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3724",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3725",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3726",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3727",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3728",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3729",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3730",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3731",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/leon/astorga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3732",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3733",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3734",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3735",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3736",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3737",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3738",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3739",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3740",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/madeira/porto-moniz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3741",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/madeira/santana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3742",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3743",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3744",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3745",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3746",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3747",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3748",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3749",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3750",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3751",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3752",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3753",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3754",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3755",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3756",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3757",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3758",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3759",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3760",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3761",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3762",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3763",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3764",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3765",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3766",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3767",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3768",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3769",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3770",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3771",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3772",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3773",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3774",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3775",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3776",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3777",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3778",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3779",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3780",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3781",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3782",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3783",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3784",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3785",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3786",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3787",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3788",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3789",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3790",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3791",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/peeling-corporal/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3792",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3793",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/pontevedra/moana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3794",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/pontevedra/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3795",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3796",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3797",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3798",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3799",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3800",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3801",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/murcia/los-alcazares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3802",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/presoterapia/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3803",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3804",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3805",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3806",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3807",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3808",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3809",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3810",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3811",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3812",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3813",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3814",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3815",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3816",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3817",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3818",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3819",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3820",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3821",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3822",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3823",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3824",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3825",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3826",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3827",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3828",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3829",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3830",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3831",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3832",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3833",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3834",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3835",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3836",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3837",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3838",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3839",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3840",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3841",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/castellon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3842",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/castellon/peniscola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3843",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3844",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3845",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3846",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3847",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3848",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3849",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3850",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3851",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3852",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3853",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3854",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3855",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3856",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3857",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3858",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3859",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3860",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3861",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3862",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/granada/loja/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3863",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3864",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3865",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3866",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3867",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3868",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3869",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3870",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3871",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3872",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3873",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3874",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3875",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3876",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3877",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3878",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3879",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3880",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3881",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3882",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3883",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3884",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3885",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3886",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3887",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3888",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3889",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3890",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3891",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3892",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3893",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3894",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3895",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3896",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3897",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3898",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3899",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3900",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3901",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3902",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-corporal/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3903",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3904",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3905",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3906",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3907",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3908",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3909",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3910",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3911",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3912",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3913",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3914",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3915",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3916",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3917",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/faro/albufeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3918",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3919",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3920",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3921",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3922",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3923",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3924",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3925",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3926",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3927",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3928",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3929",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3930",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3931",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3932",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3933",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3934",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3935",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3936",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3937",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/tratamiento-piernas-cansadas/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3938",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3939",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valencia/paterna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3940",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3941",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3942",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3943",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3944",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3945",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3946",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3947",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3948",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/vizcaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3949",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-corporales/vizcaya/urduna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3950",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3951",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3952",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3953",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3954",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3955",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3956",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3957",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3958",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3959",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3960",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3961",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3962",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3963",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3964",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3965",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3966",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3967",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3968",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3969",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3970",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3971",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3972",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3973",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3974",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3975",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3976",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3977",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3978",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3979",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3980",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3981",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3982",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3983",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3984",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3985",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3986",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3987",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3988",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3989",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3990",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3991",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3992",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3993",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3994",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3995",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3996",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3997",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3998",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 3999",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4000",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4001",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4002",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4003",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4004",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4005",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4006",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4007",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4008",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4009",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4010",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4011",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4012",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4013",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4014",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4015",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4016",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4017",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4018",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4019",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4020",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4021",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4022",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4023",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4024",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4025",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4026",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4027",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4028",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4029",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4030",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4031",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4032",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4033",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4034",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4035",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4036",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4037",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4038",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4039",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4040",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4041",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4042",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4043",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4044",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4045",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4046",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4047",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4048",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4049",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4050",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4051",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4052",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4053",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4054",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4055",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4056",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4057",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4058",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4059",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4060",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4061",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4062",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4063",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4064",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4065",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4066",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4067",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4068",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4069",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4070",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4071",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4072",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4073",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4074",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4075",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4076",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4077",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4078",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4079",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4080",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4081",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4082",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4083",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4084",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/distrito-de-faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4085",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4086",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/faro/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4087",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/faro/portimao/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4088",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4089",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4090",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4091",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4092",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4093",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4094",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4095",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4096",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4097",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4098",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4099",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4100",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial-con-mascarilla/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4101",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4102",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4103",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4104",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4105",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4106",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/badajoz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4107",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/badajoz/merida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4108",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4109",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4110",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4111",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4112",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4113",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4114",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4115",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4116",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4117",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/extremadura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4118",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4119",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4120",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4121",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4122",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4123",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4124",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4125",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4126",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4127",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4128",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4129",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4130",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4131",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/lugo/vilalba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4132",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4133",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4134",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4135",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4136",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4137",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4138",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4139",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4140",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4141",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4142",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/masaje-facial/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4143",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4144",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4145",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4146",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4147",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4148",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4149",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4150",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4151",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4152",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4153",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4154",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4155",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4156",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4157",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4158",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4159",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4160",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4161",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4162",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4163",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4164",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4165",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tarragona/montbrio-del-camp/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4166",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4167",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4168",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4169",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4170",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4171",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4172",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4173",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4174",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4175",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4176",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4177",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/a-coruna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4178",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/a-coruna/outes/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4179",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4180",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/albacete/albacete/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4181",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4182",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/alicante/alicante/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4183",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/alicante/calpe/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4184",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/alicante/denia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4185",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/alicante/rojales/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4186",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4187",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/almeria/almeria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4188",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/almeria/roquetas-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4189",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/andalucia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4190",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/aragon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4191",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4192",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/asturias/langreo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4193",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/asturias/parres/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4194",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4195",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/azores/ponta-delgada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4196",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4197",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/barcelona/barcelona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4198",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/barcelona/calella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4199",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/barcelona/la-garriga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4200",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/barcelona/seva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4201",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/barcelona/sitges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4202",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/burgos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4203",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/burgos/aranda-de-duero/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4204",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4205",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cadiz/cadiz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4206",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4207",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cadiz/rota/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4208",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cadiz/tarifa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4209",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cadiz/zahara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4210",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/canarias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4211",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4212",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cantabria/torrelavega/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4213",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/castilla-la-mancha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4214",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/castilla-y-leon/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4215",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cataluna/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4216",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4217",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/comunidad-de-madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4218",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/comunidad-valenciana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4219",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cordoba/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4220",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/cordoba/lucena/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4221",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/distrito-de-evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4222",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/distrito-de-leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4223",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/distrito-de-lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4224",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/distrito-de-oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4225",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/distrito-de-viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4226",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/distrito-de-vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4227",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4228",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/evora/evora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4229",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/fuerteventura/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4230",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/fuerteventura/antigua/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4231",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/fuerteventura/pajara/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4232",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/galicia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4233",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/girona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4234",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/girona/llanars/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4235",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/girona/lloret-de-mar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4236",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4237",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/girona/torrent/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4238",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4239",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4240",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/gran-canaria/maspalomas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4241",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/gran-canaria/mogan/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4242",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4243",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4244",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/granada/almunecar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4245",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/granada/granada/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4246",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/granada/motril/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4247",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/guipuzcoa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4248",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/guipuzcoa/hondarribia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4249",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/guipuzcoa/orio/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4250",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huelva/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4251",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huelva/ayamonte/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4252",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huelva/cartaya/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4253",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huelva/costa-occidental/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4254",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huelva/isla-cristina/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4255",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4256",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/huesca/huesca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4257",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/islas-baleares/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4258",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lanzarote/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4259",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4260",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lanzarote/yaiza/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4261",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/leiria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4262",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/leiria/monte-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4263",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/leiria/nazare/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4264",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4265",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lisboa/ericeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4266",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lisboa/lisboa/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4267",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lisboa/sintra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4268",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lisboa/torres-vedras/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4269",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lleida/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4270",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4271",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lugo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4272",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lugo/guitiriz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4273",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/lugo/panton/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4274",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4275",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/madeira/funchal/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4276",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4277",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/madrid/madrid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4278",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4279",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/estepona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4280",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/fuengirola/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4281",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/malaga/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4282",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/marbella/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4283",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/mijas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4284",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/malaga/torremolinos/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4285",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4286",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/cala-millor/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4287",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/calvia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4288",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4289",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/palma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4290",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4291",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/soller/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4292",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/mallorca/son-servera/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4293",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4294",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/murcia/murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4295",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4296",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/oporto/oporto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4297",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4298",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/oporto/vila-do-conde/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4299",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4300",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/pais-vasco/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4301",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/pontevedra/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4302",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/pontevedra/vigo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4303",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/principado-de-asturias/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4304",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/region-de-azores/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4305",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/region-de-madeira/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4306",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/region-de-murcia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4307",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/segovia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4308",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4309",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4310",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/sevilla/sevilla/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4311",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/soria/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4312",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4313",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tarragona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4314",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tarragona/el-perello/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4315",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tarragona/salou/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4316",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4317",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/adeje/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4318",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/arona/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4319",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/guia-de-isora/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4320",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4321",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4322",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/tenerife/santa-ursula/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4323",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4324",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/toledo/toledo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4325",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4326",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4327",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4328",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4329",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4330",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4331",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4332",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4333",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4334",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4335",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4336",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/tratamiento-facial/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4337",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4338",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valencia/gandia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4339",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valencia/valencia/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4340",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valladolid/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4341",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valladolid/olmedo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4342",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valladolid/penafiel/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4343",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/valladolid/san-bernarndo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4344",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/viana-do-castelo/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4345",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4346",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/viana-do-castelo/caminha/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4347",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/vila-real/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.name ?? "Treatment landing: 4348",
    path: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.path ?? "/tratamientos-faciales/vila-real/boticas/",
    props: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.props ?? false,
    meta: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta || {},
    alias: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.alias || [],
    redirect: Treatment_46pageS4zd0C957gr2SdPTRms41RLuhfElRBlNVE_45rYFItDB4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-3a2e6870-f3ca-4102-9467-4e5dec4e6c41/b2c-build/core/pages/treatment/Treatment.page.vue")
  }
]